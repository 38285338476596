import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useSettings} from '../../../hooks/use-settings'
import settingsParams from '../../../settingsParams'
import {useWidgetActions} from '../../hooks/state-provider'
import {Spinner} from '../spinner'
import s from './load-more-button.scss'

export const LoadMoreButton = ({
  mobile,
  hasMore,
  moreLoading,
}: {
  mobile?: boolean
  hasMore: boolean
  moreLoading: boolean
}) => {
  const {t} = useTranslation()
  const {loadEventsPage} = useWidgetActions()
  const {get: getSetting} = useSettings()
  const limit = getSetting(settingsParams.eventsPerPage)

  const {experiments} = useExperiments()
  const cornerRadiusAndShadowEnabled = experiments.enabled(ExperimentNames.CornerRadiusAndShadow)

  if (!hasMore && !moreLoading) {
    return null
  }

  return (
    <div
      data-hook={DH.loadMoreButtonContainer}
      className={classNames(s.container, {
        [s.mobile]: mobile,
        [s.cornerRadiusEnabled]: cornerRadiusAndShadowEnabled,
      })}
    >
      {moreLoading && (
        <div className={s.spinnerWrapper}>
          <Spinner diameter={40} />
        </div>
      )}
      <button
        data-hook={DH.loadMoreButton}
        className={classNames(s.button, {
          [s.cornerRadiusEnabled]: cornerRadiusAndShadowEnabled,
          [s.hidden]: moreLoading,
          [s.mobile]: mobile,
        })}
        type="button"
        onClick={() => loadEventsPage({limit})}
      >
        {t('loadMoreButton')}
      </button>
    </div>
  )
}
